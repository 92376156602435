<template>
  <div>
    <h3>{{ employee.name }}</h3>

    <vx-card v-for="element in getDataset()" :key="element.name" no-shadow class="mt-4">

        <!-- Undlad at vise tomme og nej værdier -->
        <div v-if="formatValue(element.value) != 'Nej' && formatValue(element.value) != '' && formatValue(element.value) != null && formatValue(element.layout) != 'Custom'" class="vx-row mb-1">
              
              <!-- <div  class="vx-row mb-1"> -->

              <div class="vx-col sm:w-2/5 w-full">
                <span>{{ element.name }}</span>
              </div>
              <div class="vx-col sm:w-2/5 w-full">
                <span><b>{{ formatValue(element.value) }}</b></span>
              </div>

        </div>

        <!-- Hvis der skal custom layout til nogle af grupperne se eksempel: Unik rettigheder -->
        <div v-if="formatValue(element.layout) == 'Custom'" class="vx-row mb-1"  v-html="element.value">
                {{ formatValue(element.value) }}  
        </div>

    </vx-card>

    <span
      v-for="departmentStatus in employee.departmentStatus"
      :key="departmentStatus.name"
    >
      <vx-card v-if="departmentAccess(departmentStatus)" no-shadow class="mt-8">
        <div
          class="vx-row mb-6"
          v-for="assignment in departmentStatus.assignments"
          :key="assignment.name"
        >
          <div class="vx-col sm:w-1/5 w-full">
            <span>{{ assignment.name }}</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <vs-checkbox
              :disabled="departmentStatus.state == 3"
              v-model="assignment.done"
              @change="completeAssignment(departmentStatus)"
            ></vs-checkbox>
          </div>
        </div>
      </vx-card>
    </span>

    <!-- <div class="flex flex-wrap items-center justify-end mt-12"> -->
    <div class="mt-4">
      <backbutton></backbutton>

      <span v-for="role in roles" :key="role" class="ml-4 mr-auto mt-2">
        <vs-button
          v-if="
            role != 'Opretter' && 
            role != 'Admin' &&
            employee.departmentStatus &&
            showSubmitButton(role)
          "
          @click="completeTask(role)"
          id="save-button"
          size="medium"
          :disabled="anyIncompleteAssignments(role)"
        >
          Afslut behandling {{ multipleDepartments(role) ? `(${role})` : "" }}
        </vs-button>
      </span>
    </div>
  </div>
</template>

<script>
import { Role } from "../../helpers/role";
import moment from "moment";

export default {
  props: {
    employeeId: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      employee: {
        // Stamdata
        ansatFinansenhed: "",
        ansatFinansenhedNavn: "",
        name: "",
        address: "",
        email: "",
        phoneNumber: "",
        cpr: "",
        profile: "",
        education: "",
        recruitedFrom: "",
        anciennitet: null,
        // Vilkår
        workHours: 37,
        workHoursPlaced: "",
        employmentDate: null,
        terminationDate: null,
        customSalary: null,
        salaryPercentageIncluded: "",
        pension: null,
        maxWorkingHours: null,
        flexjob: null,
        flexjobRemark: null,
        hourlyPaid: null,
        probation: null,
        extendedNoticeOfTermination: null,
        extendedNoticeOfTerminationEmployee: null,
        extendedNoticeOfTerminationEmployer: null,
        transferPhoneNumber: null,
        workPhoneNumber: "",
        broadband: null,
        devices: null,
        tilvalg:null,
        konteringsReference: null,
        specielleAftaler: null,
        straffeattest: null,
        departmentStatus: null,
        // SC
        behovForBil: null,
        anciennitetsTillæg: null,
        anciennitetsTillægIndregnet: null,
        kvalifikationsTillæg: null,
        kvalifikationsTillægIndregnet: null,
        // SC slut

        //indkøbssystem        
        benytterIndkobsSystem: null,
        rekvirent: null,
        lokalAftaleAnsvarlig: null,
        superBruger: null,
        laeseAdgang: null,

      },

      profile: {
        profileId: "",
        organisationsId: "",
        organisationNavn: "",
        type: "",
        eposHR: "",
        eposLøn: "",
        overenskomst: "",
        firmakort: "",
        løngruppe: "",
        andetUdstyr: "",
        digitalSignatur: "",
        pDrev: "",
        bDrev: "",
        fDrev: "",
        mDrev: "",
        businessOnline: "",
        tricom: "",
        ibinder: "",
        etray: "",
        bluePrism: "",
        powerBI: "",
        flexicapture: "",
        dreambroker: "",
        mieleVision2: "",
        managementSQL: "",
        unik: "",
        funktionsGruppe: "", //Added HK
        hardware: "",
        betaltMobil: "",
        hardwareTilvalg: "",
        easy: "",
        acubiz: "",
        visio: "",
        projektPro: "",
        yammer: "",
        ftp: "",
        eDrev: "",
        zDrev: "",
        gDrev: "",
        kDrev: "",
        rDrev: "",
        uDrev: "",
        wDrev: "",
        xDrev: "",
        yDrev: "",
        sharepointStamdatabase: "",
        sharepointRettigheder: "",
        controlManager: "",
        tdcScaleSupervisor: "",
        topdesk: "",
        trello: "",
        defgo: "",
        oracleDriver: "",
        fællespostKasser: "",
        visualStudio: ""
      },

      functionGroup : {
        funktionsGruppeId : "",
        afdeling : "",
        afdelingRolle : "",
        brugerType : "",
        brugerStatus : "",
        bogforringRettighed : "",
        funktioner : ""
      }
    };
  },

  created() {
    this.getEmployee();
    this.roles = this.$store.state.roles;
  },
  methods: {
    formatValue(value) {
      if (value === true) {
        return "Ja";
      } else if (value === false) {
        return "Nej";
      } else if (value === "Invalid date") {
        return "Nej";
      } else {
        return value;
      }
    },
    multipleDepartments() {
      if (this.roles.filter(r => r !== "Opretter").length > 1) {
        return true;
      } else return false;
    },
    departmentAccess(departmentStatus) {
      return this.roles.includes(departmentStatus.name);
    },

    prettifyDate(date) {
      if (date != "") {
        return moment(String(date)).format("DD/MM/YYYY");
      }
    },
    showSubmitButton(role) {
      return (
        this.employee.departmentStatus.find(r => r.name == role).state == 2
      );
    },
    anyIncompleteAssignments(role) {
      var assignments = this.employee.departmentStatus.find(r => r.name == role)
        .assignments;

      if (assignments.filter(r => r.done == false).length > 0) {
        return true;
      }
      return false;
    },
    completeAssignment(departmentStatus) {
      if (departmentStatus.state != 2) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Der skete en fejl"
        });
        return;
      }

      this.$vs.loading();

      var payload = this.getPayload();

      this.$http
        .put(process.env.VUE_APP_API + "/employees/" + this.employeeId, payload)
        .then(resp => {
          if (resp.data.success === true) {
            console.log("Employee updated");
          } else {
            console.log(resp.data.error);

            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: "Behandling kunne ikke afsluttes: " + resp.data.error
            });
          }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },
    completeTask(role) {
      this.$vs.loading();

      // Update status to complete
      this.employee.departmentStatus.find(r => r.name == role).state = 3;
      var payload = this.getPayload();

      this.$http
        .put(process.env.VUE_APP_API + "/employees/" + this.employeeId, payload)
        .then(resp => {
          if (resp.data.success === true) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "Behandling afsluttet"
            });

            this.$router.push({ name: "tasks" }).catch(() => {});
          } else {
            console.log(resp.data.error);

            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: "Behandling kunne ikke afsluttes: " + resp.data.error
            });
          }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },
    getPayload() {
      var payload = {
        id: this.employee.employeeId,
        organizationId: this.employee.organizationId,
        departmentManagerId: this.employee.departmentManagerId,
        departmentManagerName: this.employee.departmentManagerName,
        departmentManagerEmail: this.employee.departmentManagerEmail,
        createDate: this.employee.createDate,
        ansatFinansenhed: this.employee.ansatFinansenhed,
        ansatFinansenhedNavn: this.employee.ansatFinansenhedNavn,
        name: this.employee.name,
        address: this.employee.address,
        email: this.employee.email,
        phoneNumber: this.employee.phoneNumber,
        cpr: this.employee.cpr,
        profile: this.employee.profile,
        education: this.employee.education,
        recruitedFrom: this.employee.recruitedFrom,
        anciennitet: this.employee.anciennitet,
        workHours: this.employee.workHours,
        workHoursPlaced: this.employee.workHoursPlaced,
        employmentDate: this.employee.employmentDate,
        terminationDate: this.employee.terminationDate,
        customSalary: this.employee.customSalary,
        salaryPercentageIncluded: this.employee.salaryPercentageIncluded,
        pension: this.employee.pension,
        maxWorkingHours: this.employee.maxWorkingHours,
        flexjob: this.employee.flexjob,
        flexjobRemark: this.employee.flexjobRemark,
        hourlyPaid: this.employee.hourlyPaid,
        probation: this.employee.probation,
        extendedNoticeOfTermination: this.employee.extendedNoticeOfTermination,
        extendedNoticeOfTerminationEmployee: this.employee.extendedNoticeOfTerminationEmployee,
        extendedNoticeOfTerminationEmployer: this.employee.extendedNoticeOfTerminationEmployer,
        transferPhoneNumber: this.employee.transferPhoneNumber,
        workPhoneNumber: this.employee.workPhoneNumber,
        broadband: this.employee.broadband,
        devices: this.employee.devices,
        tilvalg: this.employee.tilvalg,
        konteringsReference: this.employee.konteringsReference,
        specielleAftaler: this.employee.specielleAftaler,
        straffeattest: this.straffeattest,
        // SC
        behovForBil: this.employee.behovForBil,
        anciennitetsTillæg: this.employee.anciennitetsTillæg,
        anciennitetsTillægIndregnet: this.employee.anciennitetsTillægIndregnet,
        kvalifikationsTillæg: this.employee.kvalifikationsTillæg,
        kvalifikationsTillægIndregnet: this.employee.kvalifikationsTillægIndregnet,
        // Indkobssystem
        benytterIndkobsSystem: this.employee.benytterIndkobsSystem,
        rekvirent: this.employee.rekvirent ? this.employee.rekvirent : false,
        lokalAftaleAnsvarlig: this.employee.lokalAftaleAnsvarlig ? this.employee.lokalAftaleAnsvarlig: false,
        superBruger: this.employee.superBruger ? this.employee.superBruger : false,
        laeseAdgang: this.employee.laeseAdgang ? this.employee.laeseAdgang : false,
        // Indkobssystem slut
        // SC slut
        state: this.employee.state,
        stateUpdateDate: this.employee.stateUpdateDate,
        departmentStatus: this.employee.departmentStatus
      };

      return payload;
    },
    getEmployee: function() {
      this.$vs.loading();

      this.$http
        .get(
          process.env.VUE_APP_API + "/employees?query=" + this.$props.employeeId
        )
        .then(resp => {
          this.employee = resp.data.find(
            employee => employee.employeeId == this.$props.employeeId
          );

          this.getProfile();
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.$vs.loading.close();
        });
                  
    },
    getProfile: function() {
      this.$vs.loading();

      this.$http
        .get(
          process.env.VUE_APP_API + "/profiles?query=" + this.employee.profile
        )
        .then(resp => {
          this.profile = resp.data.find(
            profile => profile.profile == this.$props.profile
          );

          this.getFunctionGroup();
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },
    getFunctionGroup: function() {
      //console.log('checking: ' + JSON.stringify(this.profile));
      this.$vs.loading();
      this.$http
        .get(
          process.env.VUE_APP_API + "/FunctionGroups?query=" + this.profile.funktionsGruppe
        )
        .then(resp => {
          this.functionGroup = resp.data.find(
            functionGroup => functionGroup.functionGroup == this.$props.functionGroup
          );
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },
    getDataset() {
      let full = [
        //
        // Stamdata
        //
        {
          name: "Ansættelsessted",
          value: this.employee.ansatFinansenhed + ' - ' + this.employee.ansatFinansenhedNavn,
          roles: [Role.Admin, Role.Jura, Role.HR, Role.Løn, Role.IT]
        },
        {
          name: "Jobtitel",
          value: this.profile.type,
          roles: [Role.Admin, Role.Jura, Role.HR, Role.Løn, Role.IT]
        },
        {
          name: "Navn",
          value: this.employee.name,
          roles: [Role.Admin, Role.Jura, Role.Løn, Role.HR, Role.IT]
        },
        {
          name: "Adresse",
          value: this.employee.address,
          roles: [Role.Admin, Role.Jura, Role.Løn, Role.HR, Role.IT]
        },
        {
          name: "Privat email",
          value: this.employee.email,
          roles: [Role.Admin, Role.Jura, Role.Løn, Role.HR, Role.IT]
        },
        {
          name: "Privat telefonnummer",
          value: this.employee.phoneNumber,
          roles: [Role.Admin, Role.Jura, Role.Løn, Role.HR, Role.IT]
        },
        {
          name: "CPR. nr.",
          value: this.employee.cpr,
          roles: [Role.Admin, Role.Jura, Role.Løn, Role.HR]
        },
        {
          name: "Uddannelse",
          value: this.employee.education,
          roles: [Role.Admin, Role.Jura, Role.HR, Role.Løn]
        },
        {
          name: "Ansættes fra",
          value: this.employee.recruitedFrom,
          roles: [Role.Admin, Role.Jura, Role.HR, Role.Løn, Role.IT]
        },
        {
          name: "Anciennitet overføres",
          value: this.employee.anciennitet,
          roles: [Role.Admin, Role.Jura, Role.HR, Role.Løn]
        },
        //
        // Vilkår
        //
        {
          name: "Antal arbejdstimer",
          value: `${this.employee.workHours} timer`,
          roles: [Role.Admin, Role.Jura, Role.HR, Role.Løn]
        },
        // ToDo: Fordeling workHoursPlacement
        {
          name: "Arbejdstid fordelt",
          value: this.employee.workHoursPlaced,
          roles: [Role.Admin, Role.Jura, Role.HR, Role.Løn]
        },
        {
          name: "Ansættelsesdato",
          value: this.prettifyDate(this.employee.employmentDate),
          roles: [Role.Admin, Role.Jura, Role.HR, Role.Løn, Role.IT]
        },
        // ToDo: Kun hvis tidsbegrænset
        {
          name: "Fratrædelsesdato",
          value: this.prettifyDate(this.employee.terminationDate),
          roles: [Role.Admin, Role.Jura, Role.HR, Role.Løn]
        },
        {
          name: "Aftalt timeløn",
          value: this.employee.hourlyPaid ? `${this.employee.hourlyPaid} kr.` : "Nej",
          roles: [Role.Admin, Role.Jura, Role.HR]
        },
        {
          name: "Aftalt månedsløn",
          value: this.employee.customSalary ? `${this.employee.customSalary } kr.` : "Nej",
          roles: [Role.Admin, Role.Jura, Role.HR, Role.Løn]
        },
        {
          name: "Valgfrit lønelement",
          value: this.employee.salaryPercentageIncluded,
          roles: [Role.Admin, Role.Jura, Role.HR, Role.Løn]
        },
// ESL specifikke informationer
        {
          name: "Anciennitetstillæg",
          value: this.employee.anciennitetsTillæg ? this.employee.anciennitetsTillæg : "Nej",
          roles: [Role.Admin, Role.Jura, Role.HR, Role.Løn]
        },
        {
          name: "Anciennitetstillæg indregnet i løn",
          value: this.employee.anciennitetsTillægIndregnet ? this.employee.anciennitetsTillægIndregnet  : "Nej",
          roles: this.employee.anciennitetsTillæg === true ? [Role.Admin, Role.Jura, Role.Løn, Role.HR] : []
        },
        {
          name: "Kvalifikationsstillæg",
          value: this.employee.kvalifikationsTillæg ? this.employee.kvalifikationsTillæg : "Nej",
          roles: [Role.Admin, Role.Jura, Role.HR, Role.Løn]
        },
        {
          name: "Kvalifikationstillæg indregnet i løn",
          value: this.employee.kvalifikationsTillægIndregnet ? this.employee.kvalifikationsTillægIndregnet  : "Nej",
          roles: this.employee.kvalifikationsTillæg === true ? [Role.Admin, Role.Jura, Role.Løn, Role.HR] : []
        },
        {
          name: "Behov for bil",
          value: this.employee.behovForBil ? this.employee.behovForBil : "Nej",
          roles: [Role.Admin, Role.Jura, Role.HR, Role.Løn]
        },
// ESL slut
        {
          name: "Pension",
          value: this.employee.pension,
          roles: [Role.Admin, Role.Jura, Role.HR, Role.Løn]
        },
        {
          name: "Firmakort",
          value: this.profile.firmakort,
          roles: [Role.Admin, Role.HR]
        },
        {
          name: "Højeste arbejdstid",
          value: this.employee.maxWorkingHours,
          roles: [Role.Admin, Role.Jura, Role.HR, Role.Løn]
        },
        {
          name: "Flexjob",
          value: this.employee.flexjob,
          roles: [Role.Admin, Role.Jura, Role.HR, Role.Løn, Role.IT]
        },
        {
          name: "Flexjob specielle vilkår",
          value: this.employee.flexjobRemark,
          roles: [Role.Admin, Role.Jura, Role.HR, Role.Løn]
        },
        {
          name: "Aftalt fravalg af prøvetid",
          value: this.employee.probation,
          roles: [Role.Admin, Role.Jura, Role.HR, Role.Løn]
        },
        {
          name: "Aftalt forlænget opsigelsesvarsel",
          value: this.employee.extendedNoticeOfTermination,
          roles: [Role.Admin, Role.Jura, Role.HR, Role.Løn]
        },

        {
          name: "Medarbejders forlænget opsigelsesvarsel",
          value: this.employee.extendedNoticeOfTerminationEmployee ? this.employee.extendedNoticeOfTerminationEmployee : "Nej",
          roles: [Role.Admin, Role.Jura, Role.HR, Role.Løn]
        },

        {
          name: "Arbejdsgivers forlænget opsigelsesvarsel",
          value: this.employee.extendedNoticeOfTerminationEmployer ? this.employee.extendedNoticeOfTerminationEmployer : "Nej",
          roles: [Role.Admin, Role.Jura, Role.HR, Role.Løn]
        },

        {
          name: "Bredbånd",
          value: this.employee.broadband,
          roles: [Role.Admin, Role.Jura, Role.Løn, Role.HR, Role.IT]
        },
        {
          name: "Mobiltelefon",
          value:
            this.profile.betaltMobil.toLowerCase() === "ja" ? this.employee.transferPhoneNumber === true
              ? "Overføres"
              : this.employee.transferPhoneNumber === null ? "Fravalgt" : "Nyt nummer"
              : "Nej",
          roles: [Role.Admin, Role.Jura, Role.Løn, Role.HR, Role.IT]
        },
        {
          name: "Overføres mobilnummer",
          value: this.employee.transferPhoneNumber === true ? this.employee.workPhoneNumber : "",
          roles: this.employee.transferPhoneNumber === true ? [Role.Admin, Role.Jura, Role.Løn, Role.IT] : []
        },

        { name: "Straffeattest indhentet", value: this.employee.straffeattest, roles: [Role.Admin, Role.Jura, Role.HR]},
        { name: "Specielle aftaler", value: this.employee.specielleAftaler, roles: [Role.Admin, Role.Jura, Role.Løn, Role.HR]},

        // Profile
        { name: "Type", value: this.profile.type, roles: [] }, //???
        { name: "EposHR", value: this.profile.eposHR, roles: [Role.Admin, Role.IT] },
        { name: "EposLøn", value: this.profile.eposLøn, roles: [Role.Admin, Role.IT] },
        {
          name: "Overenskomst",
          value: this.profile.overenskomst,
          roles: [Role.Admin, Role.Jura, Role.Løn, Role.HR]
        },
        { name: "AndetUdstyr", value: this.profile.andetUdstyr, roles: [] },
        {
          name: "DigitalSignatur",
          value: this.profile.digitalSignatur,
          roles: [Role.Admin, Role.IT], 
        },
        { name: "PDrev", value: this.profile.pDrev, roles: [Role.Admin, Role.IT]  },
        { name: "BDrev", value: this.profile.bDrev, roles: [Role.Admin, Role.IT]  },
        { name: "FDrev", value: this.profile.fDrev, roles: [Role.Admin, Role.IT]  },
        { name: "MDrev", value: this.profile.mDrev, roles: [Role.Admin, Role.IT]  },
        {
          name: "BusinessOnline",
          value: this.profile.businessOnline,
          roles: [Role.Admin, Role.IT] 
        },
        { name: "Tricom", value: this.profile.tricom, roles: [Role.Admin, Role.IT]  },
        { name: "Ibinder", value: this.profile.ibinder, roles: [Role.Admin, Role.IT]  },
        { name: "Etray", value: this.profile.etray, roles: [Role.Admin, Role.IT]  },
        { name: "BluePrism", value: this.profile.bluePrism, roles: [Role.Admin, Role.IT]  },
        { name: "PowerBI", value: this.profile.powerBI, roles: [Role.Admin, Role.IT] },
        {
          name: "Flexicapture",
          value: this.profile.flexicapture,
          roles: [Role.Admin, Role.IT] 
        },
        {
          name: "Dreambroker",
          value: this.profile.dreambroker,
          roles: [Role.Admin, Role.IT]
        },
        {
          name: "MieleVision2",
          value: this.profile.mieleVision2,
          roles: [Role.Admin, Role.IT] 
        },
        {
          name: "ManagementSQL",
          value: this.profile.managementSQL,
          roles: [Role.Admin, Role.IT] 
        },
        
        { name: "Unik", value: this.profile.unik, roles: [Role.Admin, Role.IT]  },
        { 
          layout: 'Custom', 
          name: "Unik Funktions grupper", 
          value: '<div class="vx-col sm:w-2/5 w-full"> <span> Unik rettigheder </span> </div> ' + '<div class="vx-col sm:w-2/5 w-full"> <span> '

                  + '<b>' + (this.profile.funktionsGruppe != null ? this.profile.funktionsGruppe + '</b>' + '<br /> <br />' + "Brugertype: " + '<b>' + this.functionGroup.brugerType + '</b>' + '<br />' +" Brugerstatus: " + '<b>' + this.functionGroup.brugerStatus  + '</b>' +'<br />' +  " Bogførring: " + '<b>' + ( this.functionGroup.bogforringRettighed === true ? 'Ja' : 'Nej' ) +'</b>' 
                  
                  + '<br><br> </div> '

                  + '<div class="vx-col sm:w-2/5 w-full"> <span> Unik funktionsgrupper </span> </div> ' + '<div class="vx-col sm:w-2/5 w-full"> <span>'

                  + '<b>' + this.functionGroup.funktioner + '</b> </span> </div>' : 'Ikke angivet </span>'),

        roles: [Role.Admin, Role.IT] 
        }, 
        { name: "Hardware", value: this.employee.devices, roles: [Role.Admin, Role.IT, Role.Jura]  },
        {
          name: "Hardware Tilvalg",
          value: this.employee.tilvalg,
          roles: [Role.Admin, Role.IT, Role.Jura] 
        },
        { name: "Konteringsreference (Ordrereference)", value: this.employee.konteringsReference, roles: [Role.Admin, Role.IT, Role.Jura]  },
        { name: "Easy", value: this.profile.easy, roles: [Role.Admin, Role.Jura, Role.Løn, Role.HR, Role.IT]  },
        { name: "Acubiz", value: this.profile.acubiz, roles: [Role.Admin, Role.Jura, Role.Løn, Role.HR, Role.IT]  },
        { name: "Visio", value: this.profile.visio, roles: [Role.Admin, Role.IT]  },
        {
          name: "ProjektPro",
          value: this.profile.projektPro,
          roles: [Role.Admin, Role.IT] 
        },
        { name: "Yammer", value: this.profile.yammer, roles: [Role.Admin, Role.IT]  },
        { name: "Ftp", value: this.profile.ftp, roles: [Role.Admin, Role.IT]  },
        { name: "EDrev", value: this.profile.eDrev, roles: [Role.Admin, Role.IT]  },
        { name: "ZDrev", value: this.profile.zDrev, roles: [Role.Admin, Role.IT]  },
        { name: "GDrev", value: this.profile.gDrev, roles: [Role.Admin, Role.IT]  },
        { name: "KDrev", value: this.profile.kDrev, roles: [Role.Admin, Role.IT]  },
        { name: "RDrev", value: this.profile.rDrev, roles: [Role.Admin, Role.IT]  },
        { name: "UDrev", value: this.profile.uDrev, roles: [Role.Admin, Role.IT]  },
        { name: "WDrev", value: this.profile.wDrev, roles: [Role.Admin, Role.IT]  },
        { name: "XDrev", value: this.profile.xDrev, roles: [Role.Admin, Role.IT]  },
        { name: "YDrev", value: this.profile.yDrev, roles: [Role.Admin, Role.IT]  },
        {
          name: "SharepointStamdatabase",
          value: this.profile.sharepointStamdatabase,
          roles: [Role.Admin, Role.IT]
        },
        {
          name: "SharepointRettigheder",
          value: this.profile.sharepointRettigheder,
          roles: [Role.Admin, Role.IT]
        },
        {
          name: "ControlManager",
          value: this.profile.controlManager,
          roles: [Role.Admin, Role.IT]
        },
        {
          name: "TdcScaleSupervisor",
          value: this.profile.tdcScaleSupervisor,
          roles: [Role.Admin, Role.IT]
        },
        { name: "Topdesk", value: this.profile.topdesk, roles: [Role.Admin, Role.IT]  },
        { name: "Trello", value: this.profile.trello, roles: [Role.Admin, Role.IT]  },
        { name: "Defgo", value: this.profile.defgo, roles: [Role.Admin, Role.IT]  },
        {
          name: "OracleDriver",
          value: this.profile.oracleDriver,
          roles: [Role.Admin, Role.IT]
        },
        {
          name: "FællespostKasser",
          value: this.profile.fællespostKasser,
          roles: [Role.Admin, Role.IT]
        },
        {
          name: "VisualStudio",
          value: this.profile.visualStudio,
          roles: [Role.Admin, Role.IT]
        }
      ];

      return full.filter(
        item =>
          item.roles.includes(this.roles.includes("Admin") ? "Admin" : "") ||
          item.roles.includes(this.roles.includes("Jura") ? "Jura" : "") ||
          item.roles.includes(this.roles.includes("HR") ? "HR" : "") ||
          item.roles.includes(this.roles.includes("Løn") ? "Løn" : "") ||
          item.roles.includes(this.roles.includes("IT") ? "IT" : "")
      );
    }
  }
};
</script>

<style lang="scss"></style>
